import ethIcon from "../images/Ticker/eth.svg";
import solanaIcon from "../images/Ticker/solana.svg";
import CosmosIcon from "../images/Ticker/cosmos.svg";
import polygonIcon from "../images/Ticker/polygon.svg";
import polkadotIcon from "../images/Ticker/polkadot.svg";

export const tickerData = [
  { name: "ETH", img: ethIcon },
  { name: "Solana", img: solanaIcon },
  { name: "Cosmos", img: CosmosIcon },
  { name: "Polygon", img: polygonIcon },
  { name: "Polkadot", img: polkadotIcon },
];
