import styled from "styled-components";
import { white } from "../../constants";

export const StatisticContainer = styled.div`
  margin-bottom: 150px;
  @media screen and (max-width: 1023px) {
    margin-bottom: 55px;
  }
`;

export const StatisticItemsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 1170px) {
    justify-content: center;
  }
`;

export const CircleContainer = styled.div`
  height: 240px;
  position: relative;
  align-items: center;
`;

export const StatisticText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${white};
  font-size: 26px;
  text-align: center;
  min-width: 200px;
`;
