import styled from "styled-components";
import { purple } from "../../constants";

export const EigenlayerContainer = styled.div`
    display: flex;
    gap: 40px;
    margin-bottom: 150px;
    @media screen and (max-width: 1023px) {
      margin-bottom: 45px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
`;

export const EigenlayerImg = styled.img`
  width: 550px;
  height: 300px;
  border-radius: 20px;
`;
export const EigenlayerLeftWrapper = styled.div``;

export const EigenlayerRightWrapper = styled.div`
`;

export const EigenlayerTextTitle = styled.div`
    color: white;
    font-size: 34px;
    font-weight: 600px;
    margin-bottom: 20px;
`;

export const EigenlayerText = styled.div`
    color: white;
    font-size: 16px;
    line-height: 20px;
`;

export const DelegateButton = styled.a`
margin-top: 45px;
  text-decoration: none;
  max-width: 340px;
  width: 100%;
  height: 60px;
  border-radius: 45.5px;
  border: 1px solid ${purple};
  color: white;
  font-size: 24px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${purple};
  }

  @media screen and (max-width: 766px) {
    min-width: 100%;
  }
`;