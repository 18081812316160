import locationIcon from "../images/AboutUs/location.svg";
import gearIcon from "../images/AboutUs/gear.svg";
import experienceIcon from "../images/AboutUs/experience.svg";
import cardBgIcon from "../images/AboutUs/cardsBackground.png";

export const aboutUsData = [
  {
    cardBg: cardBgIcon,
    cardIcon: locationIcon,
    cardTitle: "1. Location",
    cardSubTitle:
      "We are mostly located in Europe (Poland). Some of our 'peers' are from Asia.",
  },
  {
    reversal: "true",
    cardBg: cardBgIcon,
    cardIcon: gearIcon,
    cardTitle: "2. Gear",
    cardSubTitle:
      "High-performance servers and 24/7 support allow us to maintain 99% of uptime.",
  },
  {
    cardBg: cardBgIcon,
    cardIcon: experienceIcon,
    cardTitle: "3. Experience",
    cardSubTitle:
      "Enthusiasts team behind the noded.me is oriented on Web3 and crypto-growth from 2018. We dive deeply into individual networks and protocols original documentation and working only with source-provided specifications.",
  },
];
