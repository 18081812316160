import styled from "styled-components";
import { purple, white } from "../../constants";

export const TickerContainer = styled.div`
  height: 166px;
  border-top: 1px solid ${purple};
  border-bottom: 1px solid ${purple};
  margin-bottom: 110px;
  @media screen and (max-width: 1023px) {
    margin-bottom: 55px;
  }
`;

export const TickerItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TickerItemImg = styled.img`
  width: 60px;
  height: 60px;
  margin-right: 10px;
`;

export const TickerITemName = styled.div`
  font-size: 36px;
  font-weight: 600;
  color: ${white};
`;

export const TrickerItemsContainer = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;
