import {
  projectsActiveData,
  projectsParticipatedData,
} from "../../data/projectsData";
import React, { useState } from "react";
import { SectionTitle } from "../../globalElements";
import { ProjectsContainer } from "./ProjectsElements";
import ProjectsCarusel from "../../components/ProjectsCarusel";
import FadeInSection from "../../components/FadeInSection/";
import AboutProjectsModal from "../../components/AboutProjectsModal";

const ProjectsSection = () => {
  const [showAboutProjectModal, setShowAboutProjectModal] = useState(false);
  const [aboutProjectsModalItemId, setAboutProjectsModalItemId] = useState([]);

  const handleOnClickCaruselItem = (id) => {
    setAboutProjectsModalItemId([id]);
    setShowAboutProjectModal(true);
  };

  return (
    <FadeInSection>
      <AboutProjectsModal
        aboutProjectsModalItemId={aboutProjectsModalItemId}
        showModal={showAboutProjectModal}
        setShowModal={setShowAboutProjectModal}
      />
      <ProjectsContainer id="projects">
        <SectionTitle>Projects</SectionTitle>
        <ProjectsCarusel
          handleOnClick={handleOnClickCaruselItem}
          title={"Active:"}
          data={projectsActiveData}
        />
        <ProjectsCarusel
          handleOnClick={handleOnClickCaruselItem}
          title={"Participation:"}
          data={projectsParticipatedData}
        />
      </ProjectsContainer>
    </FadeInSection>
  );
};

export default ProjectsSection;
