import {
  AboutUsCard,
  AboutUsContainer,
  AboutUsImg,
  AboutUsLeft,
  AboutUsLeftSubTitle,
  AboutUsLeftTitle,
  AboutUsRight,
} from "./AboutUsElements";
import React from "react";
import { SectionTitle } from "../../globalElements";
import { aboutUsData } from "../../data/aboutUs";
import FadeInSection from "../../components/FadeInSection/";

const AboutUsSections = () => {
  return (
    <FadeInSection>
      <AboutUsContainer id="aboutus">
        <SectionTitle>About us</SectionTitle>
        {aboutUsData.map((item, index) => (
          <AboutUsCard
            style={{ backgroundImage: `url(${item.cardBg})` }}
            key={index}
          >
            <AboutUsLeft order={item.reversal}>
              <AboutUsLeftTitle>{item.cardTitle}</AboutUsLeftTitle>
              <AboutUsLeftSubTitle>{item.cardSubTitle}</AboutUsLeftSubTitle>
            </AboutUsLeft>
            <AboutUsRight order={item.reversal}>
              <AboutUsImg src={item.cardIcon} />
            </AboutUsRight>
          </AboutUsCard>
        ))}
      </AboutUsContainer>
    </FadeInSection>
  );
};

export default AboutUsSections;
