import styled from "styled-components";
import { Link as LinkS } from "react-scroll";
import { purple } from "../../constants";

export const Footer = styled.div`
  border-top: 1px solid ${purple};
  padding-top: 50px;

  @media screen and (max-width: 766px) {
    padding-top: 25px;
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 766px) {
    align-items: center;
  }
`;

export const NavLinksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const FooterLeftContainer = styled.div`
  flex: 1;
  align-items: flex-start;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;

  @media screen and (max-width: 766px) {
    margin-bottom: 25px;
  }
`;

export const FooterRightContainer = styled.div`
  flex: 1;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  display: flex;
`;

export const FooterEmail = styled.a`
  color: white;
  cursor: pointer;
  font-size: 20px;

  &:hover {
    color: ${purple};
    transition: 0.3s all ease;
  }
`;

export const FooterNavLinks = styled(LinkS)`
width: calc(100% / 3);
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  margin-bottom: 15px;
  &:hover {
    color: ${purple};
    transition: 0.3s all ease;
  }

  @media screen and (max-width: 766px) {
    display: none;
  }
`;
