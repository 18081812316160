import card1Icon from "../images/Teams/card1.svg";
import card2Icon from "../images/Teams/card2.svg";
import card3Icon from "../images/Teams/card3.svg";

export const teamData = [
  {
    position: "TeamLead",
    icon: card3Icon,
    name: "Alexander",
    github: "https://github.com/bubeha",
    linkedin: "https://www.linkedin.com/in/sasha-bubeshko-a41076150/",
  },
  {
    position: "Developer",
    icon: card1Icon,
    name: "Alex",
    linkedin: "https://www.linkedin.com/in/aleksey-lupach/",
    github: "https://github.com/AlekseyLupach",
    twitter: "https://twitter.com/CuBbl",
  },
  {
    position: "Analyst",
    icon: card2Icon,
    name: "Arthur",
    linkedin: "https://www.linkedin.com/in/arhurdrops/",
  },
];
