import {
  AboutProjectDetailsSection,
  AboutProjectDetailsSectionDesc,
  AboutProjectDetailsSectionTitle,
  AboutProjectGoToEventButton,
  AboutProjectImage,
  AboutProjectImportantItem,
  AboutProjectImportantItemValue,
  AboutProjectImportantWrapper,
  AboutProjectLeftWrapper,
  AboutProjectModalTitle,
  AboutProjectModalTitleIcon,
  AboutProjectRightWrapper,
  AboutProjectRightWrapperDesc,
  AboutProjectsModalTitleWrapper,
  AboutProjectType,
  AboutProjectTypeValue,
  AboutProjectWrapper,
  ActiveTypeValue,
  ActiveTypeValueWrapper,
  CloseModalButton,
  LoaderWrapper,
  ModalStyled,
} from "./AboutProjectsModalElements";
import Loader from "../Loader";
import React, { useEffect, useState } from "react";
import { aboutProjects } from "../../data/aboutProjects";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const AboutProjectsModal = ({
  aboutProjectsModalItemId,
  showModal,
  setShowModal,
}) => {
  const [project, setProject] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [showTabs, setShowTabs] = useState(true);
  const windowWidth = useWindowDimensions();

  const handleCloseModal = () => {
    setShowModal(false);
    setLoading(true);
  };

  useEffect(() => {
    if (windowWidth.width <= 1024) {
      setShowTabs(true);
    } else {
      setShowTabs(false);
    }
  }, [windowWidth]);

  useEffect(() => {
    if (showModal) {
      const project = aboutProjects.find(
        (p) => p.id === aboutProjectsModalItemId[0]
      );
      setProject([project]);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  useEffect(() => {
    if (showModal) {
      const x = window.scrollX;
      const y = window.scrollY;
      const disableScroll = () => {
        window.scrollTo(x, y);
      };
      window.addEventListener("scroll", disableScroll);
      return () => {
        window.removeEventListener("scroll", disableScroll);
      };
    }
  }, [showModal]);

  const AboutSection = (item) => (
    <AboutProjectRightWrapper>
      <AboutProjectModalTitle>About</AboutProjectModalTitle>
      <AboutProjectRightWrapperDesc>
        {item.description}
      </AboutProjectRightWrapperDesc>
      <AboutProjectType>Active Type:</AboutProjectType>
      <ActiveTypeValueWrapper>
        {item.activityType.map((i, index) => (
          <ActiveTypeValue key={index}>{i}</ActiveTypeValue>
        ))}
      </ActiveTypeValueWrapper>
      <AboutProjectType>Date:</AboutProjectType>
      <AboutProjectTypeValue>{item.date}</AboutProjectTypeValue>
      <AboutProjectType>Registration:</AboutProjectType>
      <AboutProjectTypeValue>{item.registration}</AboutProjectTypeValue>
      <AboutProjectType>When Reward:</AboutProjectType>
      <AboutProjectTypeValue>{item.reward}</AboutProjectTypeValue>
      <AboutProjectGoToEventButton href={item.goToEventLink} target="_blank">
        Go to event page
      </AboutProjectGoToEventButton>
    </AboutProjectRightWrapper>
  );

  const OverviewSection = (item) => (
    <>
      {!showTabs && <AboutProjectImage src={item.icon} />}
      <AboutProjectDetailsSection>
        <AboutProjectDetailsSectionTitle>
          Details
        </AboutProjectDetailsSectionTitle>
        <AboutProjectDetailsSectionDesc>
          {item.details}
        </AboutProjectDetailsSectionDesc>
      </AboutProjectDetailsSection>
    </>
  );

  return (
    <ModalStyled
      htmlOpenClassName="overflow-hidden"
      ariaHideApp={false}
      isOpen={showModal}
      onRequestClose={handleCloseModal}
      overlayClassName="Overlay"
      contentLabel="Example Modal"
    >
      {project?.map((item, index) => (
        <AboutProjectWrapper key={index}>
          <CloseModalButton onClick={handleCloseModal} />
          {isLoading ? (
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          ) : (
            <>
              <AboutProjectLeftWrapper>
                <AboutProjectsModalTitleWrapper>
                  <AboutProjectModalTitleIcon src={item.titleIcon} />
                  <AboutProjectModalTitle>{item.title}</AboutProjectModalTitle>
                </AboutProjectsModalTitleWrapper>
                <AboutProjectImportantWrapper>
                  {item.important.map((i, index) => (
                    <AboutProjectImportantItem key={index} showTabs={showTabs}>
                      <AboutProjectImportantItemValue
                        style={{ opacity: "0.6" }}
                      >
                        {i.name}
                      </AboutProjectImportantItemValue>
                      <AboutProjectImportantItemValue
                        style={{ fontSize: 16, fontWeight: 700 }}
                      >
                        {i.value}
                      </AboutProjectImportantItemValue>
                      <AboutProjectImportantItemValue>
                        {i.subValue}
                      </AboutProjectImportantItemValue>
                    </AboutProjectImportantItem>
                  ))}
                </AboutProjectImportantWrapper>
                {!showTabs && OverviewSection(item)}
                {showTabs && (
                  <Tabs>
                    <TabList>
                      <Tab>About</Tab>
                      <Tab>Overview</Tab>
                    </TabList>
                    <TabPanel>{AboutSection(item)}</TabPanel>
                    <TabPanel>{OverviewSection(item)}</TabPanel>
                  </Tabs>
                )}
              </AboutProjectLeftWrapper>
              {!showTabs && AboutSection(item)}
            </>
          )}
        </AboutProjectWrapper>
      ))}
    </ModalStyled>
  );
};

export default AboutProjectsModal;
