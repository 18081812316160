import KyveTitleIcon from "../images/Projects/kyve.svg";
import KyveIcon from "../images/AboutProjects/kyve.png";

import FortaTitleIcon from "../images/Projects/forta.svg";
import FortaIcon from "../images/AboutProjects/forta.jpg";

import IronFishTitleIcon from "../images/Projects/ironfish.svg";
import IronFishIcon from "../images/AboutProjects/ironfish.png";

import SuiTitleIcon from "../images/Projects/sui.svg";
import SuiIcon from "../images/AboutProjects/sui.png";

import StarkNetTitleIcon from "../images/Projects/starknet.svg";
import StarkNetIcon from "../images/AboutProjects/starknet.jpg";

import AptosTitleIcon from "../images/Projects/aptos.svg";
import AptosIcon from "../images/AboutProjects/aptos.png";

import CypherTitleIcon from "../images/Projects/cypher.svg";
import CypherIcon from "../images/AboutProjects/cypher.jpg";

import MinaTitleIcon from "../images/Projects/mina.svg";
import MinaIcon from "../images/AboutProjects/mina.png";

import AgoricTitleIcon from "../images/Projects/agoric.svg";
import AgoricIcon from "../images/AboutProjects/agoric.png";

import SSVTitleIcon from "../images/Projects/ssv.svg";
import SSVIcon from "../images/AboutProjects/ssv.png";

import HoprTitleIcon from "../images/Projects/hopr.svg";
import HoprIcon from "../images/AboutProjects/hopr.png";

import ChainflipTitleIcon from "../images/Projects/chainflip.svg";
import ChainflipIcon from "../images/AboutProjects/chainflip.jpg";

import EigenlayerTitleIcon from "../images/Projects/eigenlayer.svg";
import EigenlayerIcon from "../images/AboutProjects/eigenlayer.jpeg";

export const aboutProjects = [
  //Active projects:
  {
    id: 0,
    title: "Eigenlayer",
    titleIcon: EigenlayerTitleIcon,
    icon: EigenlayerIcon,
    details:
      "EigenLayer is a set of smart contracts that allows consensus layer (base layer) ETH stakers to opt-in to validating new modules on Ethereum. EigenLayer introduces a new primitive in the cryptoeconomic security space to Ethereum. Restaking enables the reuse of staked ETH tokens on the consensus layer.",
    important: [
      {
        name: "REWARD POOL",
        value: "Not set",
        subValue: "Unknown",
      },
      {
        name: "Expected profit",
        value: "Unknown",
        subValue: "Unknown",
      },
      {
        name: "Max participants",
        value: "200 in AVS",
        subValue: "Participants",
      },
      {
        name: "DropsEarn score",
        value: "Neutral",
        subValue: "Normal, Low Risks",
      },
    ],
    description:
      "EigenLayer is a set of smart contracts that allows consensus layer (base layer) ETH stakers to opt-in to validating new modules on Ethereum. EigenLayer introduces a new primitive in the cryptoeconomic security space to Ethereum. Restaking enables the reuse of staked ETH tokens on the consensus layer.",
    date: "Unknown",
    registration: "None",
    reward: "None",
    eventStatus: " You can participate (Event started, Registration open)",
    activityType: ["Testing", "Testnet", "Development", "Tech", "Bugs", "Holesky", "Goerli", "Mainet"],
    links: [
      {
        linkName: "Announcement",
        link: "https://www.blog.eigenlayer.xyz/mainnet-launch-eigenlayer-eigenda/",
      },
      {
        linkName: "Website",
        link: "https://www.eigenlayer.xyz/",
      },
      {
        linkName: "Discord",
        link: "https://discord.com/invite/eigenlayer",
      },
      {
        linkName: "Twitter",
        link: "https://twitter.com/eigenlayer",
      },
    ],
    goToEventLink:
      "https://www.blog.eigenlayer.xyz/avs-launch/",
  },
  {
    id: 5,
    title: "Aptos Incentivized Testnet 3",
    titleIcon: AptosTitleIcon,
    icon: AptosIcon,
    details:
      "The primary goal for the incentivized testnets is to ensure that this community is ready to launch a production-grade Aptos mainnet. Node operators, developers, auditors, and ecosystem builders alike are all invited to participate and will be rewarded for their contributions toward this goal. The community will engage in a number of evaluations and drills that validate the outcomes of core developer testing and also prepare us all for mainnet operation. Aptos invites and welcomes all feedback across the community during this exciting time and will host frequent community sessions to listen, iterate, and improve on these plans.",
    important: [
      {
        name: "REWARD POOL",
        value: "Not set",
        subValue: "APTOS",
      },
      {
        name: "Expected profit",
        value: "Unknown",
        subValue: "from 500 APTOS",
      },
      {
        name: "Max participants",
        value: "100",
        subValue: "Participants",
      },
      {
        name: "DropsEarn score",
        value: "Neutral",
        subValue: "Normal, Low Risks",
      },
    ],
    description:
      "The primary goal for the incentivized testnets is to ensure that this community is ready to launch a production-grade Aptos mainnet.",
    date: "30 Aug 2022 - 9 Sep 2022",
    registration: "Open",
    reward: "None",
    eventStatus: " You can participate (Event started, Registration open)",
    activityType: ["Testing", "Testnet", "Development", "Tech", "Bugs"],
    links: [
      {
        linkName: "Announcement",
        link: "https://medium.com/aptoslabs/aptos-incentivized-testnet-roadmap-209be695c77c",
      },
      {
        linkName: "Website",
        link: "https://aptoslabs.com/",
      },
      {
        linkName: "Discord",
        link: "https://discord.com/invite/aptoslabs",
      },
      {
        linkName: "Twitter",
        link: "https://mobile.twitter.com/aptoslabs",
      },
    ],
    goToEventLink:
      "https://medium.com/aptoslabs/aptos-incentivized-testnet-roadmap-209be695c77c",
  },
  {
    id: 4,
    title: "StarkNet Testnet",
    titleIcon: StarkNetTitleIcon,
    icon: StarkNetIcon,
    details:
      "StarkNet is a permissionless decentralized Validity-Rollup (also known as a “ZK-Rollup”). It operates as an L2 network over Ethereum, enabling any dApp to achieve unlimited scale for its computation – without compromising Ethereum’s composability and security, thanks to StarkNet’s reliance on the safest and most scalable cryptographic proof system – STARK.",
    important: [
      {
        name: "REWARD POOL",
        value: "Not set",
        subValue: "Not set",
      },
      {
        name: "Expected profit",
        value: "Unknown",
        subValue: "Unknown",
      },
      {
        name: "Max participants",
        value: "∞",
        subValue: "No limit",
      },
      {
        name: "DropsEarn score",
        value: "Neutral",
        subValue: "Normal, Low Risks",
      },
    ],
    description:
      "StarkNet is a permissionless decentralized Validity-Rollup (also known as a “ZK-Rollup”)",
    date: "TBA",
    registration: "Open",
    reward: "None",
    eventStatus: " You can participate (Event started, Registration open)",
    activityType: ["Testing", "Tech", "Bugs"],
    links: [
      {
        linkName: "Announcement",
        link: "https://medium.com/starkware/starknet/home",
      },
      {
        linkName: "Website",
        link: "https://starknet.io/",
      },
      {
        linkName: "Discord",
        link: "https://discord.com/invite/KuU6STNAEB",
      },
    ],
    goToEventLink: "https://medium.com/starkware/starknet/home",
  },
  {
    id: 3,
    title: "Sui Incentivized Testnet",
    titleIcon: SuiTitleIcon,
    icon: SuiIcon,
    details:
      "Sui is a smart contract platform maintained by a permissionless set of validators that play a role similar to validators or miners in other blockchain systems. Sui offers scalability and unprecedented low-latency for simple use cases. Sui makes the vast majority of transactions processable in parallel, which makes better use of processing resources, and offers the option to increase throughput with more resources. Sui forgoes consensus to instead use simpler and lower-latency primitives for simple use cases, such as payment transactions and assets transfer. This is unprecedented in the blockchain world and enables a number of new latency-sensitive distributed applications, ranging from gaming to retail payment at physical points of sale. Sui is written in Rust and supports smart contracts written in the Move programming language to define assets that may have an owner. Move programs define operations on these assets including custom rules for their creation, the transfer of these assets to new owners, and operations that mutate assets. Sui has a native token called SUI, with a fixed supply. The SUI token is used to pay for gas, and is also used as delegated stake on validators within an epoch. The voting power of validators within this epoch is a function of this delegated stake. Validators are periodically reconfigured according to the stake delegated to them. In any epoch the set of validators is Byzantine fault tolerant. At the end of the epoch, fees collected through all transactions processed are distributed to validators according to their contribution to the operation of the system. Validators can in turn share some of the fees as rewards to users that delegated stake to them. Sui is backed by a number of state-of-the-art peer-reviewed works and years of open source development.",
    important: [
      {
        name: "REWARD POOL",
        value: "Not set",
        subValue: "SUI",
      },
      {
        name: "Expected profit",
        value: "SUI",
        subValue: "SUI",
      },
      {
        name: "Max participants",
        value: "∞",
        subValue: "No limit",
      },
      {
        name: "DropsEarn score",
        value: "High",
        subValue: "Normal, Low Risks",
      },
    ],
    description: "Sui Testnet is launching this August.",
    date: "TBA",
    registration: "Closed",
    reward: "None",
    eventStatus: "You can't participate(Event ended)",
    activityType: [
      "Testing",
      "Tech",
      "Incentivized testnet",
      "Bugs",
      "Community leaders",
    ],
    links: [
      {
        linkName: "Announcement",
        link: "https://sui.io/resources-sui/announcing-sui-incentivized-testnet/?utm_source=dropsearn",
      },
      {
        linkName: "Website",
        link: "https://docs.sui.io/",
      },
      {
        linkName: "Twitter",
        link: "https://twitter.com/Mysten_Labs",
      },
      {
        linkName: "Discord",
        link: "https://discord.com/invite/sui",
      },
    ],
    goToEventLink:
      "https://sui.io/resources-sui/announcing-sui-incentivized-testnet/?utm_source=dropsearn",
  },
  {
    id: 2,
    title: "Iron Fish Incentivized Testnet",
    titleIcon: IronFishTitleIcon,
    icon: IronFishIcon,
    details:
      "Iron Fish has raised a $27.7M Series A led by a16z with participation from Elad Gil, Sequoia, Electric Capital, Dylan Field, Alan Howard, Jeff Weiner (Next Play Ventures), MetaStable, A Capital, Divesh Makan (Iconiq), Do Kwon (Terra), Matt Luongo (Keep Network), Nathan McCauley (Anchorage), Arrington XRP, and many other amazing people. Iron Fish community members all around the world have given feedback, contributed code, translated installation instructions to a different languages, wrote scripts for how to install Iron Fish even faster, made YouTube videos on how to install Iron Fish on hosting providers, and much more. The incentivized testnet will track member participation going forward in leaderboard points that can be earned through various categories. Points will lead to future mainnet Iron Fish coins for eligible participants.",
    important: [
      {
        name: "REWARD POOL",
        value: "420,000 IRON",
        subValue: "IRON",
      },
      {
        name: "Expected profit",
        value: "Proportionally",
        subValue: "Points",
      },
      {
        name: "Max participants",
        value: "∞",
        subValue: "No limit",
      },
      {
        name: "DropsEarn score",
        value: "Medium",
        subValue: "Normal, Low Risks",
      },
    ],
    description:
      "On December 1st Iron Fish are launching the Iron Fish Incentivized Testnet to reward their awesome community members (and new ones!) for the continued support as the project moves towards the mainnet launch. The testnet will allow to give back to the community and create many more future Iron Fish coin holders around the world.",
    date: "1 Dec 2021 - Today",
    registration: "Closed",
    reward: "None",
    eventStatus: "You can't participate(Event ended)",
    activityType: [
      "Registration",
      "Social networks",
      "Testnet",
      "Tech",
      "Marketing",
      "Bugs",
    ],
    links: [
      {
        linkName: "Announcement",
        link: "https://ironfish.network/blog/2021/11/30/series-a-incentivized-testnet/?utm_source=dropsearn",
      },
      {
        linkName: "Twitter",
        link: "https://twitter.com/ironfishcrypto",
      },
      {
        linkName: "Telegram",
        link: "https://t.me/ironfishcryptochat",
      },
      {
        linkName: "Event page",
        link: "https://testnet.ironfish.network/about?utm_source=dropsearn",
      },
    ],
    goToEventLink:
      "https://testnet.ironfish.network/about?utm_source=dropsearn",
  },
  {
    id: 1,
    title: "Forta",
    titleIcon: FortaTitleIcon,
    icon: FortaIcon,
    details:
      "Real-time threat detection for smart contracts. Forta is the first community-based, security protocol for the decentralized economy.",
    important: [
      {
        name: "REWARD POOL",
        value: "Not set",
        subValue: "Not set",
      },
      {
        name: "Expected profit",
        value: "Unknown",
        subValue: "Unknown",
      },
      {
        name: "Max participants",
        value: "∞",
        subValue: "No limit",
      },
      {
        name: "DropsEarn score",
        value: "Neutral",
        subValue: "Normal, Low Risks",
      },
    ],
    description:
      "Forta is a real-time detection network for security & operational monitoring of blockchain activity As a decentralized monitoring network, Forta detects threats and anomalies on DeFi, NFT, governance, bridges and other Web3 systems in real-time. Given timely and relevant alerts about the security and health of owned or dependent systems, protocols and investors can react quickly to neutralize threats and prevent or minimize loss of funds.",
    date: "25 Apr 2022 - Today",
    registration: "Closed",
    reward: "None",
    eventStatus: "You can't participate(Event ended)",
    activityType: ["Testing", "Testnet", "Bugs"],
    links: [
      {
        linkName: "Website",
        link: "https://forta.org/",
      },
      {
        linkName: "Twitter",
        link: "https://twitter.com/fortaprotocol",
      },
      {
        linkName: "Telegram",
        link: "https://t.me/FortaProtocol",
      },
    ],
    goToEventLink: "https://forta.org/",
  },
  {
    id: 6,
    title: "KYVE Incentivized Testnet: Mission Korellia",
    titleIcon: KyveTitleIcon,
    icon: KyveIcon,
    details:
      "A long time ago in a galaxy far, far away, a team of determined, passionate individuals banded together to build a system that they hoped would revolutionize the way the world stores and queries its data. They wanted to build a system that was open to all. One that was permissionless but could be trusted, one with a built-in process of verification to cut down the number of centralized intermediaries and malicious third parties. A system that was cost-efficient but streamlined at the same time. And so, this team of individuals built the foundation for a layer 1 blockchain that would enable projects to standardize, validate and permanently store their data streams. They built KYVE.  It goes without saying that a foundation remains flat without construction. But the data revolution isn’t one that can be spurred by a mere few individuals. The KYVE Network needs the help and support of other impassioned individuals who want to build the future of decentralized data. It’s been a long journey in the making, but Kyve is excited to finally launch KYVE’s Korellia incentivized testnet. When a product launches its incentivized testnet, it is launching a period of testing and development, essentially allowing the people building the product to verify how said product will perform in a real-world context. In the simplest of terms, KYVE’s incentivized testnet will enable the team to test and verify how different aspects of the KYVE Network are functioning and how they can be improved for the community members. The data gathered from this incentivized testnet will be used to improve the Network, ensuring Kyve is providing the community with the best possible decentralized data lake experience.",
    important: [
      {
        name: "REWARD POOL",
        value: "Not set",
        subValue: "20,000,000 KYVE",
      },
      {
        name: "Expected profit",
        value: "Unknown",
        subValue: "KYVE",
      },
      {
        name: "Max participants",
        value: "∞",
        subValue: "No limit",
      },
      {
        name: "DropsEarn score",
        value: "Medium",
        subValue: "Normal, Low Risks",
      },
    ],
    description:
      "KYVE has announced the Incentivized Testnet: Mission Korellia. Join to become an early, involved contributor to KYVE’s launch on mainnet, and earn rewards for providing input.",
    date: "5 May 2022 - 15 Aug 2022",
    registration: "Closed",
    reward: "None",
    eventStatus: "You can't participate(Event ended)",
    activityType: [
      "Testing",
      "Social networks",
      "Contest",
      "Testnet",
      "Community leaders",
      "Bugs",
    ],
    links: [
      {
        linkName: "Announcement",
        link: "https://blog.kyve.network/kyve-launches-incentivized-testnet-mission-korellia-d53280a17c6d",
      },
      {
        linkName: "Twitter",
        link: "https://twitter.com/KYVENetwork",
      },
      {
        linkName: "Telegram",
        link: "https://t.me/kyvenet",
      },
    ],
    goToEventLink:
      "https://blog.kyve.network/kyve-launches-incentivized-testnet-mission-korellia-d53280a17c6d",
  },
  //Participation projects:
  {
    id: 7,
    title: "NUCypher",
    titleIcon: CypherTitleIcon,
    icon: CypherIcon,
    details:
      "Umbral is NuCypher's threshold proxy re-encryption scheme. Alice (the data owner) can delegate decryption rights to Bob (the recipient) for any ciphertext intended for her, through a re-encryption process performed by a set of trust-minimized proxies. When a threshold of these proxies participate by performing re-encryption, Bob is able to combine these independent re-encryptions and decrypt the original message using his private key.",
    important: [
      {
        name: "REWARD POOL",
        value: "Not set",
        subValue: "NUCypher",
      },
      {
        name: "Expected profit",
        value: "Unknown",
        subValue: "NUCypher",
      },
      {
        name: "Max participants",
        value: "∞",
        subValue: "No limit",
      },
      {
        name: "DropsEarn score",
        value: "Medium",
        subValue: "Normal, Low Risks",
      },
    ],
    description:
      " Cryptographic Infrastructure for Privacy-Preserving Applications.",
    date: "TBA",
    registration: "Closed",
    reward: "Distributed after mainnet launch.",
    eventStatus: "You can't participate (Event ended)",
    activityType: ["Testing", "Testnet"],
    links: [
      {
        linkName: "Website",
        link: "https://www.nucypher.com/",
      },
      {
        linkName: "Discord",
        link: "https://discord.com/invite/7rmXa3S",
      },
      { linkName: "Github", link: "https://github.com/nucypher/" },
    ],
    goToEventLink: "https://www.nucypher.com/",
  },
  {
    id: 8,
    title: "Mina Testworld - Adversarial Testnet",
    titleIcon: MinaTitleIcon,
    icon: MinaIcon,
    details:
      "Mina's adversarial testnet is up and running, and ready for you to explore!",
    important: [
      {
        name: "REWARD POOL",
        value: "8,000,000 MINA",
        subValue: "MINA",
      },
      {
        name: "Expected profit",
        value: "up to 40,000 MINA",
        subValue: "MINA",
      },
      {
        name: "Max participants",
        value: "∞",
        subValue: "No limit",
      },
      {
        name: "DropsEarn score",
        value: "High",
        subValue: "Normal, Low Risks",
      },
    ],
    description:
      "Mina's adversarial testnet is up and running, and ready for you to explore! Take part in our adversarial testnet challenges for a chance to win rewards in US$ and tokens, and increase your eligibility for Genesis!",
    date: "From 22 Dec 2020",
    registration: "Closed",
    reward: "Distributed after mainnet launch.",
    eventStatus: "You can't participate (Event ended)",
    activityType: ["Testing", "Testnet", "Development", "Tech", "Bugs"],
    links: [
      {
        linkName: "Official Event Page",
        link: "https://minaprotocol.com/testworld",
      },
      {
        linkName: "Testworld Community Challenges",
        link: "https://forums.minaprotocol.com/t/testworld-community-challenges/763",
      },
    ],
    goToEventLink: "https://minaprotocol.com/testworld",
  },
  {
    id: 9,
    title: "Agoric Incentivized Testnet",
    titleIcon: AgoricTitleIcon,
    icon: AgoricIcon,
    details:
      "The Agoric testnet is a series of global, competitive challenges for validators to prepare, learn, and earn points in advance of Agoric's mainnet. The testnet will consist of five (5) phases, each phase focused on testing a different aspect of validating and securing the Agoric blockchain network. Points are earned by completing network and community tasks during each phase, with bonus points awarded to the winners of the challenge tasks. The Agoric team will provide all validators with technical support on our Discord, weekly updates with important information on our updates page, and announcements for the start of each phase. The testnet will start with Phase 1: Onboarding on March 22nd, 2021.",
    important: [
      {
        name: "REWARD POOL",
        value: "1,000,000 tokens",
        subValue: "Tokens, Points",
      },
      {
        name: "Expected profit",
        value: "Unknown",
        subValue: "Tokens, Points",
      },
      {
        name: "Max participants",
        value: "150",
        subValue: "Seats Max",
      },
      {
        name: "DropsEarn score",
        value: "Very High",
        subValue: "Hard, Low Risks",
      },
    ],
    description:
      "The Agoric testnet is a series of global, competitive challenges for validators to prepare, learn, and earn points in advance of Agoric's mainnet. The testnet will consist of five (5) phases, each phase focused on testing a different aspect of validating and securing the Agoric blockchain network. Points are earned by completing network and community tasks during each phase, with bonus points awarded to the winners of the challenge tasks.",
    date: "22 Mar 2021 - 26 Jun 2021",
    registration: "Closed",
    reward: "None",
    eventStatus: "You can't participate (Event ended)",
    activityType: [
      "Registration",
      "Complete Tasks",
      "Nodes",
      "Community",
      "Development",
      "Validator",
    ],
    links: [
      {
        linkName: "Event page",
        link: "https://validate.agoric.com/",
      },
      {
        linkName: "Validator Guide",
        link: "https://github.com/Agoric/agoric-sdk/wiki/Validator-Guide#get-the-validator-public-key",
      },
    ],
    goToEventLink: "https://validate.agoric.com/",
  },
  {
    id: 10,
    title: "SSV.Network Incentivized Testnet",
    titleIcon: SSVTitleIcon,
    icon: SSVIcon,
    details:
      "ssv.network is the first public implementation of SSV (Secret-Shared-Validators) technology. What started as a research paper conceptualized in collaboration with members of the Ethereum Foundation, later labeled DVT (Distributed Validator Technology) is now a fully functional open source decentralized ETH staking incentivized testnet. At its core, DVT (aka SSV) enables the distributed operation of an Ethereum validator across varying operators. ssv.network is an infrastructure layer designed to promote decentralization, client diversity, fault tolerance and resilience to ETH stakres and the Ethereum protocol. Why is SSV/DVT important for Ethereum DVT is an integral building block in Ethereum’s transition to POS, as detailed in the EF roadmap. Distributed validator technology is the technological implementation needed to help scale POS staking without compromising on security integrity and more importantly, decentralization. There are infrastructural improvements the ecosystem needs in order to further lower the barrier of entry, increase competitiveness and promote client diversity. The network is a mission critical component in ensuring security, fault tolerance and client diversity for stakers, and the Ethereum ecosystem at large. The ssv.network protocol is structured as a public good which allows stakers, service providers and builders to easily access a Decentralized ETH staking infrastructure.",
    important: [
      {
        name: "REWARD POOL",
        value: "~$430,000",
        subValue: "64,000 SSV",
      },
      {
        name: "Expected profit",
        value: "Unknown",
        subValue: "SSV",
      },
      {
        name: "Max participants",
        value: "∞",
        subValue: "No limit",
      },
      {
        name: "DropsEarn score",
        value: "Neutral",
        subValue: "Normal, Low Risks",
      },
    ],
    description:
      "After a few busy months and four iterations — the long-anticipated Incentivized Testnet for SSV based staking is finally starting. Open to early ssv.network testers and newcomers alike — anyone can participate, test the network and earn rewards for it.",
    date: "24 Jan 2022 - 4 Apr 2022",
    registration: "Closed",
    reward: "None",
    eventStatus: "You can't participate (Event ended)",
    activityType: ["Staking", "Testing", "Testnet", "Tech"],
    links: [
      {
        linkName: "Event page",
        link: "https://ssv.network/testnet/",
      },
      {
        linkName: "Announcement",
        link: "https://blog.ssv.network/primus-incentivized-testnet-is-live-1cb82d9b4709?gi=8dfaa7a0ec73",
      },
      {
        linkName: "Twitter",
        link: "https://twitter.com/ssv_network",
      },
    ],
    goToEventLink:
      "https://blog.ssv.network/primus-incentivized-testnet-is-live-1cb82d9b4709?gi=8dfaa7a0ec73",
  },
  {
    id: 11,
    title: "Hopr Wildhorn Testnet",
    titleIcon: HoprTitleIcon,
    icon: HoprIcon,
    details:
      "This incentivized testnet, named Wildhorn, is open to everyone and will help the HOPR core dev team to gather data about the stability of the latest version of the HOPR protocol. Participants can earn four ranks of the Polygon Tester NFT, which will boost the APR of tokens staked in the HOPR staking program by between 1% and 5%, depending on rank.",
    important: [
      {
        name: "REWARD POOL",
        value: "Not set",
        subValue: "Not set",
      },
      {
        name: "Expected profit",
        value: "NFT tokens",
        subValue: "NFT",
      },
      {
        name: "Max participants",
        value: "∞",
        subValue: "No limit",
      },
      {
        name: "DropsEarn score",
        value: "Neutral",
        subValue: "Normal, Low Risks",
      },
    ],
    description:
      "HOPR Wildhorn incentivized testnet launches Aug 26th on Polygon. Run nodes and stress-test the HOPR protocol to earn NFT-boost staking rewards.",
    date: "26 Aug 2021 - 2 Sep 2021",
    registration: "Closed",
    reward: "None",
    eventStatus: "You can't participate (Event ended)",
    activityType: ["Testnet", "Nodes", "Development", "Web3", "Polygon"],
    links: [
      {
        linkName: "Event page",
        link: "hhttps://network.hoprnet.org/",
      },
      {
        linkName: "Announcement",
        link: "https://medium.com/hoprnet/hopr-wildhorn-incentivized-testnet-launches-on-polygon-e25e8630268f",
      },
      {
        linkName: "Twitter",
        link: "https://twitter.com/hoprnet",
      },
    ],
    goToEventLink:
      "https://medium.com/hoprnet/hopr-wildhorn-incentivized-testnet-launches-on-polygon-e25e8630268f",
  },
  {
    id: 12,
    title: "Chainflip Labs: Soundcheck Incentivised Testnet",
    titleIcon: ChainflipTitleIcon,
    icon: ChainflipIcon,
    details:
      "Soundcheck will commence on the 15th of December 2021 and will conclude on the 19th of January 2022.250,000 FLIP tokens are up for grabs and will be distributed proportionately to users based on their leaderboard standings. Completing various tasks, participating in updates, uncovering bugs and providing feedback will all earn points on the leaderboard. E.g. participant scores 100,000 points - the total points earned by participants is 4,000,000 - user will earn 2.5% of the available tokens (250,000), 6250 FLIP.",
    important: [
      {
        name: "REWARD POOL",
        value: "250,000 FLIP",
        subValue: "FLIP",
      },
      {
        name: "Expected profit",
        value: "Proportionally",
        subValue: "FLIP",
      },
      {
        name: "Max participants",
        value: "∞",
        subValue: "No limit",
      },
      {
        name: "DropsEarn score",
        value: "Medium",
        subValue: "Normal, Low Risks",
      },
    ],
    description:
      "Chainflip Labs is pumped to announce Soundcheck Incentivised Testnet. Soundcheck is a dry run for the Sandstorm release, which as community members will know, is a giant milestone for the project.",
    date: "15 Dec 2021 - 19 Jan 2022",
    registration: "Closed",
    reward: "None",
    eventStatus: "You can't participate (Event ended)",
    activityType: [
      "Staking",
      "Testing",
      "Testnet",
      "Auction",
      "Tech",
      "Validator",
      "Bugs",
    ],
    links: [
      {
        linkName: "Website",
        link: "https://chainflip.io/",
      },
      {
        linkName: "Announcement",
        link: "https://blog.chainflip.io/soundcheck/?utm_source=dropsearn",
      },
      {
        linkName: "Twitter",
        link: "https://twitter.com/chainflip",
      },
    ],
    goToEventLink: "https://blog.chainflip.io/soundcheck/?utm_source=dropsearn",
  },
];
