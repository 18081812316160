export const statisticData = [
  { number: "4", symbols: "+", desc: " Years of experience", progress: 80 },
  {
    number: "35+",
    symbols: "+",
    desc: " Nodes with a perfect launch",
    progress: 75,
  },
  { number: "5", symbols: "+", desc: " Active projects daily", progress: 95 },
  { number: "99%", symbols: "%", desc: " Uptime", progress: 99 },
];
