import {
  CarouselTitle,
  ProjectItem,
  ProjectItemImg,
  ProjectItemName,
  ProjectsCaruselContainer,
} from "./ProjectsCaruselElements";
import React from "react";
import Slider from "react-slick";

let settings = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 6,
  responsive: [
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 580,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const ProjectsCarusel = ({ title, data, handleOnClick }) => {
  return (
    <ProjectsCaruselContainer>
      <CarouselTitle>{title}</CarouselTitle>
      <Slider {...settings}>
        {data.map((item, index) => (
          <ProjectItem key={index} onClick={() => handleOnClick(item.id)}>
            <ProjectItemImg src={item.icon} />
            <ProjectItemName>{item.name}</ProjectItemName>
          </ProjectItem>
        ))}
      </Slider>
    </ProjectsCaruselContainer>
  );
};

export default ProjectsCarusel;
