import React from "react";
import { SectionTitle } from "../../globalElements";
import { TeamContainer } from "./TeamElements";

import FadeInSection from "../../components/FadeInSection/";
import TeamsCarusel from "./components/TeamsCarusel";

const TeamsSection = () => {
  return (
    <FadeInSection>
      <TeamContainer id="team">
        <SectionTitle>Team</SectionTitle>
        <TeamsCarusel />
      </TeamContainer>
    </FadeInSection>
  );
};

export default TeamsSection;
