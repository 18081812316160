import { Link } from "react-scroll";
import styled from "styled-components";
import { purple, white } from "../../constants/index";

export const HeroContainer = styled.div`
  margin-bottom: 90px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @media screen and (max-width: 1023px) {
    flex-direction: column;
    margin-bottom: 45px;
  }
`;

export const HeroLeftContent = styled.div`
  flex: 1;
  @media screen and (max-width: 1023px) {
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    order: 2;
  }
`;

export const HeroRightContent = styled.div`
  flex: 1;
`;

export const HeroImg = styled.img`
  width: 100%;
  height: 100%;
`;

export const HeroH1Span = styled.span`
  color: ${white};
`;

export const LearnMoreButton = styled(Link)`
  margin-top: 30px;
  max-width: 310px;
  width: 100%;
  height: 60px;
  border-radius: 45.5px;
  border: 1px solid ${purple};
  color: white;
  font-size: 24px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${purple};
  }

  @media screen and (max-width: 766px) {
    min-width: 100%;
  }
`;
