import {
  StatisticContainer,
  CircleContainer,
  StatisticText,
  StatisticItemsContainer,
} from "./StatisticElements";
import Circle from "react-circle";
import React, { useEffect, useState } from "react";
import AnimatedNumber from "animated-number-react";
import { SectionTitle } from "../../globalElements";
import { purple } from "../../constants";
import { statisticData } from "../../data/statisticData";
import FadeInSection from "../../components/FadeInSection";

const StatisticSection = () => {
  const [isTimeout, setTimeuot] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setTimeuot(true);
    }, 1000);
  }, []);

  const formatValue = (value, symbols) =>
    `${Number(value).toFixed(0)}${symbols}`;

  return (
    <FadeInSection>
      <StatisticContainer id="statistics">
        <SectionTitle>Statistics</SectionTitle>
        <StatisticItemsContainer>
          {statisticData.map((item, index) => (
            <CircleContainer key={index}>
              <Circle
                animate={true}
                animationDuration="2s"
                responsive={false}
                size="240"
                lineWidth="10"
                progress={isTimeout ? item.progress : 0}
                progressColor={purple}
                bgColor={"#373737"}
                percentSpacing={10}
                roundedStroke={false}
                showPercentage={false}
                showPercentageSymbol={true}
              />
              <StatisticText>
                <AnimatedNumber
                  className="animatedNumber"
                  delay={500}
                  value={item.number}
                  formatValue={(value) => formatValue(value, item.symbols)}
                />
                {item.desc}
              </StatisticText>
            </CircleContainer>
          ))}
        </StatisticItemsContainer>
      </StatisticContainer>
    </FadeInSection>
  );
};

export default StatisticSection;
