import styled, { keyframes } from "styled-components";
import { purple, white } from "../../../../constants";

export const TeamCard = styled.div`
  display: flex !important;
  flex-direction: row !important;
  border-radius: 20px;
  width: 370px !important;
  margin: 0 auto;
  // background-image: ${(props) => `url(${props.Icon})`} !important;
  border: 1px solid ${purple};
  background-repeat: no-repeat;
  background-size: cover;
`;

export const TeamCardIconContainer = styled.div`
  height: 180px;
  width: 140px;
`;

export const TeamCardIcon = styled.img`
  width: 140px;
  height: 180px;
  object-fit: cover;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
`;

export const TeamCardProfileInfo = styled.div`
  padding: 20px;
  display:flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const TeamCardName = styled.div`
  font-size: 32px;
  color: ${white};
  font-weight: 300;

  @media screen and (max-width: 1023px) {
    font-size: 28px;
  }
`;

export const TeamCardPosition = styled.div`
  font-size: 18px;
  color: ${white};
  font-weight: 300;

  @media screen and (max-width: 1023px) {
    font-size: 22px;
  }
`;

export const TeamSocialLinksWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const TeamSocialIconLink = styled.a`
  cursor: pointer;
  margin-right: 10px;
`;

const Shake = keyframes`
   10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`;

export const TeamSocialIcon = styled.img`
  width: 30px;
  height: 30px;

  &:hover {
    animation: ${Shake} 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
`;
