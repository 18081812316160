import {
  Footer,
  FooterContainer,
  FooterEmail,
  FooterLeftContainer,
  FooterNavLinks,
  FooterRightContainer,
  NavLinksContainer,
} from "./FooterElements";
import React from "react";
import { menuData } from "../../data/menuData";
import { SectionWrapper } from "../../globalElements";
import { animateScroll as scroll } from "react-scroll";
import { NavLogo } from "../../components/Navbar/NavbarElements";
import FadeInSection from "../../components/FadeInSection/";

const FooterSections = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };
  return (
    <FadeInSection>
      <Footer id={"contacts"}>
        <SectionWrapper>
          <FooterContainer>
            <NavLogo to="/" onClick={toggleHome}>
              noded.me
            </NavLogo>
            <NavLinksContainer>
              {menuData.map((item, index) => (
                <FooterNavLinks
                  to={item.link}
                  key={index}
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  {item.title}
                </FooterNavLinks>
              ))}
            </NavLinksContainer>
            <FooterEmail href="mailto:infonoded@pm.me">
              infonoded@pm.me
            </FooterEmail>
          </FooterContainer>
        </SectionWrapper>
      </Footer>
    </FadeInSection>
  );
};

export default FooterSections;
