import styled from "styled-components";
import { gray200, purple } from "../../constants";
import Modal from "react-modal";

export const ModalStyled = styled(Modal)`
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  background-color: #1b1e22;
  z-index: 2;
  padding: 40px;
  color: white;
  border-radius: 20px;
  overflow: auto;

  @media screen and (max-width: 960px) {
    padding: 20px;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #959799;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #995ef6;
    border-radius: 20px;
    border: 2px solid #959799;
  }
`;

export const AboutProjectModalTitleIcon = styled.img`
  width: 44px;
  height: 44px;
  margin-right: 10px;
`;

export const AboutProjectImage = styled.img`
  margin-top: 20px;
  border-radius: 20px;
  max-width: 100%;
`;

export const AboutProjectModalTitle = styled.div`
  font-size: 24px;
  font-weight: 700;
`;

export const AboutProjectDetailsSection = styled.h2`
  margin-top: 20px;
`;
export const AboutProjectDetailsSectionTitle = styled.div``;

export const AboutProjectDetailsSectionDesc = styled.p`
  font-size: 16px;
  font-weight: 400;
`;

export const AboutProjectsModalTitleWrapper = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
`;

export const AboutProjectWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const AboutProjectLeftWrapper = styled.div`
  flex: 1.5;
  margin-right: 80px;

  @media screen and (max-width: 960px) {
    margin-right: 0px;
  }
`;

export const AboutProjectRightWrapper = styled.div`
  flex: 0.5;
  display: flex;
  flex-direction: column;
`;

export const AboutProjectRightWrapperDesc = styled.div`
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 20px;
  margin-top: 20px;
`;

export const AboutProjectGoToEventButton = styled.a`
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: ${purple};
  cursor: pointer;
  border-radius: 10px;
  font-weight: 600;
  opacity: 0.9;
  margin-top: 20px;

  &:hover {
    opacity: 1;
    transition: 0.3s ease-in-out;
  }
`;

export const AboutProjectType = styled.div`
  font-size: 14px;
  opacity: 0.6;
  margin-bottom: 5px;
`;

export const ActiveTypeValueWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const ActiveTypeValue = styled.div`
  border: 1px solid red;
  padding: 5px 10px;
  border-radius: 20px;
  border-color: ${purple};
  font-size: 16px;
  margin-bottom: 10px;
  margin-right: 10px;
`;

export const AboutProjectTypeValue = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
`;

export const AboutProjectImportantWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  @media screen and (max-width: 960px) {
    flex-wrap: wrap;
  }
`;

export const AboutProjectImportantItem = styled.div`
  min-width: 140px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: ${(props) =>
    !props.showTabs ? `1px solid ${gray200}` : "none"};
`;

export const AboutProjectImportantItemValue = styled.div`
  font-size: 14px;
`;

export const CloseModalButton = styled.div`
  cursor: pointer;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  border-radius: 50%;
  border: 1px solid #ffffff;
  position: absolute;
  top: 20px;
  right: 20px;
  opacity: 0.8;
  align-items: center;
  justify-content: center;
  display: flex;

  @media screen and (max-width: 960px) {
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
  }

  &:hover {
    opacity: 1;
  }
  &::before,
  &::after {
    position: absolute;
    content: " ";
    height: 23px;
    width: 2px;
    background-color: #ffffff;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80vh;
`;
