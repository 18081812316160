import React from "react";
import { animateScroll as scroll } from "react-scroll";
import useWindowPosition from "../../hooks/useWindowPosition";
import {
  ArrowImg,
  UpButton,
  UpButtonInside,
  UpButtonSize,
} from "./GoTopButtonElements";

import ArrowIcon from "../../images/arrow.svg";

const GoTopButton = () => {
  const scrollOffSetY = useWindowPosition();

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  let documentHeight = document.body.clientHeight;
  let scrollHeight = window.scrollY;
  let windowHeight = window.innerHeight;
  let scrollPercent = (scrollHeight / (documentHeight - windowHeight)) * 100;

  return (
    <UpButton onClick={toggleHome} scrollOffSetY={scrollOffSetY}>
      <UpButtonSize style={{ height: scrollPercent + "%" }} />
      <UpButtonInside>
        <ArrowImg src={ArrowIcon} />
      </UpButtonInside>
    </UpButton>
  );
};

export default GoTopButton;
