import styled from "styled-components";

export const Nav = styled.nav`
  background: "transparent";
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`;

export const UpButton = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
  cursor: pointer;
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 500px;
  transform: ${(props) => props.scrollOffSetY > 100 ? `scale(1)` : `scale(0)`};
  transition: all 0.7s ease-in-out;

  &:after {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 40px;
    height: 40px;
    background: linear-gradient(
      45deg,
      rgba(79, 82, 223, 1) 0%,
      rgba(241, 6, 155, 1) 100%
    );
    border-radius: 50%;
    content: "";
    z-index: -1;
  }

  &:hover {
    transform: scale(1.2) !important;
  }
`;

export const UpButtonSize = styled.div`
  background: #ffffff;
`;

export const UpButtonInside = styled.div`
  position: absolute;
  top: 2px;
  left: 2px;
  width: 36px;
  height: 36px;
  color: white;
  background: linear-gradient(
    45deg,
    rgba(79, 82, 223, 1) 0%,
    rgba(241, 6, 155, 1) 100%
  );
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ArrowImg = styled.img`
  width: 20px;
`;
