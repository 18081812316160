import {
  TickerContainer,
  TickerItem,
  TickerItemImg,
  TickerITemName,
  TrickerItemsContainer,
} from "./TickerElements";
import React from "react";
import Marquee from "react-fast-marquee";
import { tickerData } from "../../data/tickerData";

const TickerSection = () => {
  return (
    <TickerContainer id="ticker">
      <TrickerItemsContainer>
        <Marquee speed={50} gradientWidth={0}>
          {tickerData.map((item, index) => (
            <TickerItem key={index} style={{ marginRight: 80 }}>
              <TickerItemImg src={item.img} />
              <TickerITemName>{item.name}</TickerITemName>
            </TickerItem>
          ))}
        </Marquee>
      </TrickerItemsContainer>
    </TickerContainer>
  );
};

export default TickerSection;
