import styled from "styled-components";
import { white } from "./constants";

export const Wrapper = styled.div``;

export const SectionWrapper = styled.div`
  max-width: 1170px;
  margin: 0 auto;
  @media screen and (max-width: 1170px) {
    padding: 0 20px;
  }
`;

export const SectionTitle = styled.div`
  font-size: 32px;
  font-weight: 700;
  color: ${white};
  text-align: center;
  margin-bottom: 50px;

  @media screen and (max-width: 1023px) {
    margin-bottom: 25px;
    font-size: 42px;
  }
`;
