import styled from "styled-components";
import { lightPurple, purple, white } from "../../constants";

export const ProjectsCaruselContainer = styled.div`
  margin-bottom: 50px;
`;

export const ProjectItem = styled.div`
  cursor: pointer;
  font-size: 28px;
  font-weight: 300;
  color: ${white};
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;

  @media screen and (max-width: 1023px) {
    font-size: 28px;
  }

  &:hover p {
    &::after {
      width: 100%;
      transition: width 0.3s;
    }
  }
`;

export const ProjectItemName = styled.p`
  &::after {
    content: "";
    display: block;
    width: 0;
    height: 2px;
    background: ${purple};
    transition: width 0.3s;
  }
`;

export const ProjectItemImg = styled.img`
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
  text-align: center;
  align-items: center;
`;

export const CarouselTitle = styled.div`
  color: ${lightPurple};
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 40px;

  @media screen and (max-width: 1023px) {
    margin-bottom: 20px;
    font-size: 36px;
  }
`;
