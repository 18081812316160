import EigenlayerIcon from "../images/Projects/eigenlayer.svg";
import FortaIcon from "../images/Projects/forta.svg";
import IronFishIcon from "../images/Projects/ironfish.svg";
import SuiIcon from "../images/Projects/sui.svg";
import StarkNetIcon from "../images/Projects/starknet.svg";
import AptosIcon from "../images/Projects/aptos.svg";
import KyveIcon from "../images/Projects/kyve.svg";

import CypherIcon from "../images/Projects/cypher.svg";
import MinaIcon from "../images/Projects/mina.svg";
import AgoricIcon from "../images/Projects/agoric.svg";
import SSVIcon from "../images/Projects/ssv.svg";
import ChainflipIcon from "../images/Projects/chainflip.svg";
import HoprIcon from "../images/Projects/hopr.svg";

export const projectsActiveData = [
  { id: 0, name: "Eigenlayer", icon: EigenlayerIcon },
  { id: 1, name: "Forta", icon: FortaIcon },
  { id: 2, name: "Iron Fish", icon: IronFishIcon },
  { id: 3, name: "Sui", icon: SuiIcon },
  { id: 4, name: "StarkNet", icon: StarkNetIcon },
  { id: 5, name: "Aptos", icon: AptosIcon },
  { id: 6, name: "KYVE", icon: KyveIcon },
];

export const projectsParticipatedData = [
  { id: 7, name: "NUCypher", icon: CypherIcon },
  { id: 8, name: "Mina", icon: MinaIcon },
  { id: 9, name: "Agoric", icon: AgoricIcon },
  { id: 10, name: "SSV", icon: SSVIcon },
  { id: 11, name: "Hopr", icon: HoprIcon },
  { id: 12, name: "Chainflip", icon: ChainflipIcon },
];
