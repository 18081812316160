import styled from "styled-components";
import { lightPurple, white } from "../../constants";

export const AboutUsContainer = styled.div`
  margin-bottom: 150px;
  @media screen and (max-width: 1023px) {
    margin-bottom: 55px;
  }
`;

export const AboutUsCard = styled.div`
  width: 100%;
  display: flex;
  border-radius: 50px;
  padding: 50px 60px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 30px;

  @media screen and (max-width: 1023px) {
    padding: 25px 30px;
  }

  @media screen and (max-width: 766px) {
    flex-direction: column;
  }
`;

export const AboutUsLeft = styled.div`
  flex: 1;
  order: ${({ order }) => (order !== undefined ? 1 : 0)};
`;

export const AboutUsRight = styled.div`
  flex: 1;
  display: flex;
  justify-content: ${({ order }) =>
    order !== undefined ? "flex-start" : "flex-end"};
  margin-right: 20px;
`;

export const AboutUsImg = styled.img`
  max-width: 129px;
  max-height: 129px;
  width: 100%;
  height: 100%;
  @media screen and (max-width: 766px) {
    display: none;
  }
`;

export const AboutUsLeftTitle = styled.div`
  color: ${lightPurple};
  font-size: 28px;
  font-weight: 600;

  @media screen and (max-width: 1023px) {
    font-size: 26px;
  }
`;

export const AboutUsLeftSubTitle = styled.div`
  font-weight: 300;
  color: ${white};
  font-size: 24px;

  @media screen and (max-width: 1023px) {
    font-size: 24px;
  }
`;
