import React from "react";
import { SectionTitle } from "../../globalElements";
import FadeInSection from "../../components/FadeInSection/";
import { DelegateButton, EigenlayerContainer, EigenlayerImg, EigenlayerLeftWrapper, EigenlayerRightWrapper, EigenlayerText, EigenlayerTextTitle } from "./EigenlayerSectionsElements";
import EigenlayerIcon from "../../images/AboutProjects/eigenlayer.jpeg";

const EigenlayerSections = () => {
  return (
    <FadeInSection>
      <SectionTitle>EigenLayer</SectionTitle>
      <EigenlayerContainer>
        <EigenlayerLeftWrapper>
          <EigenlayerImg src={EigenlayerIcon} />
        </EigenlayerLeftWrapper>
        <EigenlayerRightWrapper>
          <EigenlayerTextTitle>About EigenLayer</EigenlayerTextTitle>
          <EigenlayerText>
            EigenLayer is a protocol built on the Ethereum network that leverages a new concept, “restaking, ” to extend the security and utility of the network to other projects in the industry.
          </EigenlayerText>
          <EigenlayerText style={{ marginTop: "30px" }}>
            We are pleased to announce that we have gone live on EigenDA Mainnet.
          </EigenlayerText>
          <DelegateButton target="_blank" href={"https://app.eigenlayer.xyz/operator/0x33be23aa50fbf7141a1a00defec028f4d44e2099"}>Delegate to EigenLayer</DelegateButton>
        </EigenlayerRightWrapper>
      </EigenlayerContainer>
    </FadeInSection>
  );
};

export default EigenlayerSections;
