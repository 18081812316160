import React, { useState } from "react";
import { HomeBg } from "./HomeElements";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import { SectionWrapper } from "../globalElements";

import HeroSection from "../sections/HeroSection";
import TickerSection from "../sections/TickerSection";
import StatisticSection from "../sections/StatisticSection";
import ProjectsSection from "../sections/ProjectsSection";
import TeamsSection from "../sections/TeamsSection";
import FooterSections from "../sections/FooterSections";

import AboutUsSections from "../sections/AboutUsSections";
import GoTopButton from "../components/GoTopButton/GoTopButton";
import EigenlayerSections from "../sections/EigenlayerSections";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <HomeBg>
        <SectionWrapper>
          <Sidebar isOpen={isOpen} toggle={toggle} />
          <Navbar toggle={toggle} />
          <HeroSection />
        </SectionWrapper>
        <TickerSection />
        <SectionWrapper>
          <EigenlayerSections />
          <StatisticSection />
          <ProjectsSection />
          <TeamsSection />
          <AboutUsSections />
        </SectionWrapper>
        <FooterSections />
      </HomeBg>
      <GoTopButton />
    </>
  );
};

export default Home;
