import {
  HeroContainer,
  LearnMoreButton,
  HeroLeftContent,
  HeroRightContent,
  HeroImg,
} from "./HeroElements";
import React from "react";
import HeroImage from "../../images/HeroImage.png";
import { lightPurple, purple } from "../../constants";
import TypeWriterEffect from "react-typewriter-effect";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import FadeInSection from "../../components/FadeInSection/";

const HeroSection = () => {
  const { width } = useWindowDimensions();

  return (
    <FadeInSection>
      <HeroContainer id="home">
        <HeroLeftContent>
          <TypeWriterEffect
            textStyle={{
              color: lightPurple,
              fontWeight: 700,
              fontSize: width > 425 ? 55 : 35,
            }}
            startDelay={1000}
            cursorColor={purple}
            multiText={["Ecosystem-driven Noded team"]}
            multiTextDelay={1000}
            typeSpeed={30}
          />
          <LearnMoreButton to="statistics" smooth={true} duration={500}>
            Learn more
          </LearnMoreButton>
        </HeroLeftContent>
        <HeroRightContent>
          <HeroImg src={HeroImage} />
        </HeroRightContent>
      </HeroContainer>
    </FadeInSection>
  );
};

export default HeroSection;
