import {
  TeamCard,
  TeamCardIcon,
  TeamCardIconContainer,
  TeamCardName,
  TeamCardPosition,
  TeamCardProfileInfo,
  TeamSocialIcon,
  TeamSocialIconLink,
  TeamSocialLinksWrapper,
} from "./TeamsCaruselElements";
import React from "react";
import Slider from "react-slick";
import { teamData } from "../../../../data/teamData";

import cardBackground from "../../../../images/Teams/cardBackground.png";

import TwitterIcon from "../../../../images/Teams/twitter.svg";
import GithubIcon from "../../../../images/Teams/github.svg";
import LinkedinIcon from "../../../../images/Teams/linkedin.svg";

const TeamsCarusel = () => {
  let settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1169,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 920,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 620,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {teamData.map((item, index, row) => (
        <TeamCard Icon={cardBackground} key={index}>
          <TeamCardIconContainer>
            <TeamCardIcon src={item.icon} />
          </TeamCardIconContainer>
          <TeamCardProfileInfo>
            <TeamCardName>{item.name}</TeamCardName>
            <TeamCardPosition>{item.position}</TeamCardPosition>
            <TeamSocialLinksWrapper>
              {item.twitter ? <TeamSocialIconLink href={item.twitter} target="_blank">
                <TeamSocialIcon src={TwitterIcon} />
              </TeamSocialIconLink> : null}
              {item.github ? <TeamSocialIconLink href={item.github} target="_blank">
                <TeamSocialIcon src={GithubIcon} />
              </TeamSocialIconLink> : null}
              {item.linkedin ? <TeamSocialIconLink href={item.linkedin} target="_blank">
                <TeamSocialIcon src={LinkedinIcon} />
              </TeamSocialIconLink> : null}
            </TeamSocialLinksWrapper>
          </TeamCardProfileInfo>
        </TeamCard>
      ))}
    </Slider>
  );
};

export default TeamsCarusel;
